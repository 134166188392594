<template>
  <div class="flex items-center justify-center w-full h-screen">
    <div class="md:w-6/12 w-10/12">
      <h2 class="text-2xl font-bold mb-5">Admin Sign In.</h2>
      <span
        v-show="message !== ''"
        :class="`mb-3 block text-xs font-semibold ${
          error ? 'text-red-500' : 'text-green-500'
        }`"
        >{{ message }}</span
      >
      <a-form-model
        ref="signinForm"
        :rules="rules"
        layout="vertical"
        :model="form"
      >
        <a-form-model-item label="Email" prop="email">
          <a-input
            v-model="form.email"
            type="email"
            :disabled="signing_in"
            placeholder="Email"
          />
        </a-form-model-item>
        <a-form-model-item label="Password" prop="password">
          <a-input-password
            v-model="form.password"
            :disabled="signing_in"
            placeholder="Enter your password"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button :loading="signing_in" type="primary" @click="onSubmit">
            Sign In
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>
<script>
import lawyerAuthApi from '../../api/lawyers/auth'
export default {
  name: 'SignIn',
  layout: 'adminauth',
  data() {
    return {
      error: false,
      message: '',
      signing_in: false,
      form: {
        email: '',
        password: '',
      },
      rules: {
        email: [
          {
            pattern:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: 'Enter a valid email address',
            trigger: 'blur',
          },
        ],
        password: {
          required: true,
          message: 'Enter your password',
          trigger: 'blur',
        },
      },
    }
  },
  head: {
    title: 'Sign In | Legal Naija',
  },
  methods: {
    async loginUser() {
      this.message = ''
      this.signing_in = true
      const req = await lawyerAuthApi(this.axios).authLogin({
        email: this.form.email,
        password: this.form.password,
      })
      if (req.error) {
        this.error = true
        this.message = req.message
        this.signing_in = false
        return
      }

      if (
        req.data.user.role === 'admin' ||
        req.data.user.role === 'super-admin'
      ) {
        this.error = false
        this.message = req.message
        this.signing_in = false
        this.$store.commit('auth/SET_USER', req.data.user)
        this.$store.commit('auth/SET_TOKEN', req.data.token)
        setTimeout(() => {
          this.$router.push('/admin/dashboard')
        }, 2000)
      } else {
        this.$notification.error({
          message: 'Sign Rejected',
          description: 'Invalid username or password',
        })
        this.signing_in = false
      }
    },
    onSubmit() {
      this.$refs.signinForm.validate((valid) => {
        if (valid) {
          this.loginUser()
        } else {
          return false
        }
      })
    },
  },
}
</script>

import { errorResponse } from '../response'

const lawyerAuthApi = ($axios) => {
  const http = $axios
  return {
    async authLogin({ email, password }) {
      const payload = { email, password }
      try {
        const req = await http.post('/auth/login', payload)
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async authRegister(data) {
      return await http.post('/auth/register', data)
    },
  }
}

export default lawyerAuthApi
